@media screen and (max-width: 991px) {
    .report-table td:first-child {
        border-top: none !important;
    }

    .report-table tbody tr:first-child th {
        border-left: none;
    }

    .report-table-hiring-title {
        border-left: none !important;
    }

    .report-table td:last-child {
        border-top: 1px solid #dee2e6 !important;
    }

    .report-table thead {
        width: 70%;
    }

    .report-table thead tr{
        width: 100%;
    }

    .report-table thead th {
        width: 100%;
        overflow-x: auto;
        padding: 10px 5px !important;
        vertical-align: middle;
    }

    .report-table tbody {
        width: 30%;
    }

    .report-table tbody tr {
        width: 100%;
    }

    .report-table tbody td {
        padding: .75rem;
    }
}

@media screen and (max-width: 576px) {
    .report-table {
        width: 100% !important;
    }
}

@media screen and (min-width: 991px) {
    .report-table-hiring-title {
        border-top: none !important;
    }
}

.report-table th {
    color: #aab3bd !important;
}


.report-table {
    margin: 0 !important;
}

.table-responsive {
    overflow-x: initial !important;
}

.report-table-wrap {
    overflow: auto;
}