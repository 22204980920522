.resume-form {
    max-width: 612px;
}

.key-skill-input-wrapper {
    position: relative;
}

.key-skill-add-button {
    position: absolute;
    right: 0;
    top: 29px;
    height: 40px;
}

.key-skill-container {
    display: flex;
    flex-wrap: wrap;
}

.key-skill-item {
    display: flex;
    margin: 5px 5px 5px 0;
    background-color: #eff4f4;
    border-radius: 15px;
    line-height: 50px;
}

.key-skill-delete-wrapper {
    text-align: center;
    width: 30px;
    cursor: pointer;
}

.key-skill-item-desc {
    display: inline-block;
    margin: 0 5px 0 0;
    padding-left: 10px;
}

.key-skill-delete {
    transition: .3s;
}

.key-skill-delete-wrapper:hover {
    background-color: rgba(203,209,211,.6);
    border-radius: 0 15px 15px 0;
}

.resume-from-education-field-set {
    position: relative;
}

.resume-from-education-delete {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.resume-form-language-level {
    min-width: 250px;
    margin-left: 10px;
    width: 100%;
}

.resume-form-language-name {
    width: 100%;
    min-width: 230px;
}

.resume-page-personal-avatar {
    border-radius: 10px;
}

.resume-form .resume-form-textarea {
    min-height: 150px;
}

.key-skill-enter {
    animation: key-skill-add 800ms ease-out forwards;
}

.key-skill-delete-animation {
    animation: key-skill-delete 800ms ease-out forwards;
}

.key-skill-exit {
    display: none;
}

.education-add-animation {
    animation: education-add 150ms ease-out forwards !important;
}

.education-delete-animation {
    animation: education-delete 300ms ease-out forwards !important;
}

.language-add-animation {
    animation: language-add 300ms ease-out !important;
}

.language-delete-animation {
    animation: language-delete 300ms ease-out !important;
}

.suggestion-input {
    border: 1px solid transparent;
}

.key-skill-input {
    padding-right: 35%;
}

.language-wrapper {
    align-items: center;
}

.resume-page-language-fields-wrapper {
    display: flex;
    align-items: center;
}

.language-wrapper .item-icon {
    margin-left: 10px;
}

.resume-page-education-item {
    display: flex;
}

.resume-page-education-description {
    margin-left: 10px;
}

.education-field-wrapper {
    position: relative;
}

.resume-page-personal-info li,
.resume-page-about-me{
    word-break: break-all;
}


@media screen and (max-width: 768px) {
    .key-skill-input {
        padding-right: 10%;
    }

    .language-wrapper .item-icon {
        margin-left: 5px;
    }

    .resume-page-education-item {
        flex-direction: column;
    }

    .resume-page-education-description {
        margin-left: 0;
    }
}

.resume-page-badge {
    white-space: break-spaces;
    word-break: break-word;
    text-align: left;
}

@media screen and (max-width: 550px) {
    .resume-page-language-fields-wrapper {
        flex-direction: column;
        align-items: start;
    }

    .resume-form-language-level {
        margin: 0;
    }

    .language-wrapper {
        align-items: start;
    }

    .language-wrapper .item-icon {
        margin-top: 40px;
    }

    .resume-form-language-name {
        min-width: 265px;
    }
}

@media screen and (max-width: 365px) {
    .resume-form-language-name,
    .resume-form-language-level {
        min-width: 240px;
    }
}

.suggestion-input-focus-border {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.suggestion-input:focus {
    background-color: #ffffff;
    border: 1px solid #b9cece;
}

.suggestion-list-wrapper {
    width: 100%;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border: 1px solid #b9cece;
    border-top: none;
    position: absolute;
    top: 69px;
    z-index: 100;
    max-height: 250px;
    overflow: hidden;
}

.suggestion-list {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    max-height: 250px;
    overflow: auto;
}

.suggestion-list > li {
    color: #8e97b2;
    background-color: #FFFFFF;
    padding: 8px 0 8px 22px;
}

.suggestion-list > li:last-child {
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
}

.suggestion-list > li:hover {
    cursor: pointer;
    background-color: #e5eaed;
}

@keyframes key-skill-delete {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-30px);
        opacity: 0;
        display: none;
    }
}

@keyframes key-skill-add {
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes education-add {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes education-delete {
    0% {
        transform: translateX(0px);
    }

    70% {
        transform: translateX(500px);
        opacity: 0;
    }

    100% {
        display: none;
    }
}

@keyframes language-add {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes language-delete {
    0% {
        transform: translateX(0px);
    }

    70% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        display: none;
    }
}
