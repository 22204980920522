.applicant__item {
    margin-bottom: 24px;
}

.applicant__label {
    margin-bottom: 13px;
}

.response-left__edit-icon {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 5px;
    z-index: 2;
}

.response-question {
    margin-bottom: 24px;
}

.response-question__label {
    margin-bottom: 13px;
}

/* INFO */

.response-info {
    padding-top: 45px;
}

.response-info__item {
    margin-bottom: 25px;
}

.response-info__label {
    margin-bottom: 10px;
}

.response-info__value {
    color: #354169;
    font-size: 22px;
    word-wrap: break-word;
}

.application-status {
    display: flex;
}

.application-status__reset-button {
    margin-left: 15px;
}

.application-status__reset-button:hover {
    color: #31384d;
}

/* TAGS */

.response-tags {
    padding-bottom: 45px;
    border-bottom: 1px solid #e3eded;
}

.response-tags__item {
    margin: 2px 5px 3px 0;
}

/* COMMENTS */

.comments {
    padding: 45px 0 0;
}

.comments__title {
    margin-bottom: 15px;
}

.comments__item {
    padding: 27px 0;
    border-top: 1px solid #e3eded;
}

.comment__info {
    display: flex;
    margin-bottom: 20px;
}

.comments__item:nth-child(2) {
    border-top: none;
}

.comment__user-icon {
    padding-top: 2px;
    margin-right: 20px;
}

.comment__user-name {
    font-size: 16px;
    color: #10816f;
    letter-spacing: 0.4px;
}

.comment__icons-container {
    padding-left: 15px;
    margin-left: auto;
    flex-shrink: 0;
}

.comment__icons-container :last-child {
    margin-left: 20px;
}

.comment-textarea {
    min-height: 120px;
}

.no-comments {
    padding: 25px 0 35px;
    font-size: 16px;
    color: #354169;
}

td.no-comments {
    text-align: center;
}

th {
    font-weight: normal;
}

@media screen and (min-width: 768px) {
    .response-info {
        padding-top: 0;
    }
    .response-tags {
        border-bottom: none;
        padding-bottom: 15px;
    }
}

@media screen and (min-width: 992px) {
    .response-info {
        padding-top: 0;
    }
    .response-info__label {
        margin-bottom: 0;
        padding-top: 8px;
    }
    .comment__flex {
        display: flex;
    }
    .comment__user-name {
        margin-right: 18px;
    }
}

.responses-filter-open-box {
    color: #02a88d;
    cursor: pointer;
}

.responses-filter-open-box .responses-filter-icon {
    color: #b2e2db;
    margin-right: 15px;
}

.responses-filter-open-box .responses-filter-chevron-icon {
    color: #02a88d;
    margin-left: 15px;
    font-size: 10px;
}

.responses-page-filter-container {
    margin: 0 -15px 25px -15px;
    background: #e9eeef;
    display: block;
    padding: 10px 0;
}

.responses-page-filter-container div.react-select-menu-is-close,
.responses-page-filter-container div.react-select-menu-is-open {
    margin: 10px;
}

.responses-page-filter-container .checkbox-mark {
    background-color: #fff;
}

.responses-checkboxes-group .checkbox-container {
    display: block;
}

.responses-checkboxes-group .checkbox-container:first-child {
    margin-bottom: 20px;
}

.responses-checkboxes-group {
    margin: 10px 20px;
    display: inline-block;
    width: calc( 50% - 40px );
}

@media screen and (min-width: 576px) {

    .responses-filter-open-box {
        margin-bottom: 0;
    }

    .responses-page-filter-container {
        margin-left: 0;
        margin-right: 0;
        border-radius: 10px;
        padding: 10px;
    }

    .responses-checkboxes-group {
        margin: 10px 0;
        width: auto;
    }

    .responses-checkboxes-group .checkbox-container:first-child {
        margin-bottom: 0;
    }

    .responses-checkboxes-group .checkbox-container {
        margin: 15px 15px 5px 15px;
        width: auto;
        display: inline-block;
    }
    
    .responses-page-filter-container div.react-select-menu-is-close,
    .responses-page-filter-container div.react-select-menu-is-open {
        display: inline-block;
        width: 300px;
    }
}

.response-item-name {
    display: flex;
}


.form-file-container {
    position: relative;
    left: 5px;
    padding-top: 20px;
}

.form-file {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
}

.form-file-info {
    margin-left: 15px;
}

.form-group .button-add-item {
    color: #0bb79b;
}

@media screen and (min-width: 768px) {
    .form-group .button-add-item {
        font-size: 16px;
    }
}

.form-file-container .button-add-item {
    cursor: pointer;
}

.search-response-container {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: white;
}

.search-response__input {
    margin-bottom: 0;
    width: 100%;
}

@media screen and (max-width: 576px){
    .search-response-container{
        margin-bottom: 40px;
    }    
}

@media screen and (max-width: 576px) {
    .responses-wrap .vacancies-page-sort .multiple-select,
    .responses-wrap .vacancies-page-sort .mobile-button-multiple-menu {
        margin-top: -40px;
        margin-bottom: 0;
    }
    .responses-wrap .vacancies-page-sort .button-multiple {
        margin-top: -37px;
        margin-bottom: 0;
    }
    .mobile-multiple-menu button {
        white-space: nowrap;
    }
}

@media screen and (max-width: 376px) {
    .responses-wrap .main-page-title {
        width: 100%;
    }
}

.content-changes-history {
    white-space: nowrap;
    color: #354169;
}

.content-changes-history-title {
    white-space: nowrap;
    color:  #a3a4a9;
}

.response-top {
    margin-bottom: 60px;
}

.response-top__back-button {
    color: #02a88d;
    font-size: 18px;
    position: relative;
}

.response-top__back-button_arrow {
    position: absolute;
    padding-right: 30px;
    left: -45px;
}