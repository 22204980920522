/* vacancy-form */

.vacancy-form {
    max-width: 612px;
    padding-bottom: 45px;
}

.vacancy-form__field {
    margin-bottom: 35px;
}

.vacancy-form__field .form-group {
    margin-bottom: 0;
}

.vacancy-form__button-add-item {
    margin-bottom: 30px;
}

.vacancy-form__questions {
    padding:  30px 0 55px;
}

/* vacancy-questions */

.vacancy-questions__title {
    margin-bottom: 25px;
}

.vacancy-questions__field {
    display: flex;
    align-items: flex-end;
}

.vacancy-questions__delete-icon {
    padding: 0 0 12px 25px;
}

.vacancy-questions__field .form-group {
    margin-bottom: 0;
}

.vacancy-questions__description {
    padding: 20px 0;
}