a {
    color: #06b291;
}

a:hover {
    color: #047b63;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:focus {
    outline: none;
}

.link {
    display: inline-block;
    font-size: 14px;
}

.return-link {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
}

.return-link__arrow {
    width: 8px;
    height: 8px;
    border-top: 1px solid #06b291;
    border-left: 1px solid #06b291;
    transform: rotate(-45deg);
    margin-right: 25px;
    transition: border-color 1.3s ease;
}

.return-link__text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
}

.return-link:hover .return-link__arrow {
    border-color: #047b63;
}

@media screen and (min-width: 1300px) {
    .return-link {
        margin-left: -30px;
    }
}