.vacancy-content {
    min-height: 97.5vh;
}

.vacancy-page-description {
    font-size: 18px;
}

.vacancy-page-main-title {
    font-size: 24px;
}

.vacancy-page-required {
    font-size: 16px;
    color: #74757B;
}

.copy-by-click-icon {
    cursor: pointer;
}

.vacancy-page-desc {
    font-size: 18px;
    color: #354169;
    line-height: 1.2;
}

.vacancy-page-part {
    margin-bottom: 40px;
}

.vacancy-page-picture-wrapper {
    min-width: 250px;
    max-width: 250px;
    height: 250px;
    padding-left: 15px;
    margin-right: 10%;
}

.vacancy-page-picture-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5%;
}

@media screen and (max-width: 550px) {
    .vacancy-page-required {
        flex-direction: column;
    }
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 100%;
    }
}
