.resume-page__button-panel {
    justify-content: flex-end;
    align-items: center;
}
.resume-page-section-title-wrapper {
    justify-content: space-between;
    align-items: center;
}

.resume-page-information-wrapper {
    display: flex;
}

.resume-page-personal-info-wrapper {
    margin-left: 6%;
}

.resume-page-wrapper {
    padding: 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
}

.resume-page-personal {
    width: 100%;
    padding-bottom: 10px;
}

.border-bottom {
    border-bottom: 1px grey solid;
}

.resume-page-personal-photo-wrapper {
    margin: 0 auto;
    display: flex;
    width: 100%;
    height: 200px;
    max-width: 200px;
}

.resume-page-personal-avatar {
    object-fit: cover;
    width: 100%;
}

.resume-page-personal-avatar:hover {
    cursor: zoom-in;
}

.modal-body .resume-page-personal-avatar {
    cursor: zoom-out;
}

.resume-page-avatar-change-button {
    text-align: center;
    width: 100%;
}

.resume-page-avatar-change-button:hover {
    cursor: pointer;
    color: #0aa78d;
}

.resume-page-personal-info {
    padding: 0;
    list-style: none;
}

.resume-page-personal-wrapper {
    display: flex;
}

.resume-page-info-block {
    font-size: 16px;
}

.resume-page__button-panel .item-icon {
    color: #354169;
    font-size: 20px;
    margin-right: 10px;
}

.resume-page__button-panel .item-icon:hover,
.resume-page__button-panel .button-icon:hover{
    cursor: pointer;
    color: #0aa78d;
}

.resume-page-education {
    padding-bottom: 15px;
}

.resume-download-wrapper {
    position: relative;
}

.resume-page-download-types {
    left: 0;
    position: absolute;
    width: 150px;
    box-shadow: 0 3px 15px rgba(51,51, 51, .2);
    border-radius: 5px;
    top: 25px;
    background: #FFFFFF;
}

.resume-page-download-type {
    padding: 5px 5px;
}

.resume-page-download-type > span:nth-child(2) {
    color: #8e97b2;
}

.resume-page-download-type:hover {
    cursor: pointer;
    background-color: #e9eeef;
}

@media screen and (max-width: 1373px) {
    .resume-page-download-types {
        left: -110px;
        top: 25px;
    }
}

@media screen and (max-width: 600px) {
    .resume-page-personal-wrapper {
        flex-direction: column;
    }

    .resume-page-personal-avatar {
        max-width: 300px;
    }

    .resume-page-section-title-wrapper > h3 {
        font-size: 20px;
    }

    .resume-page-info-block {
        font-size: 14px;
    }
}

@media screen and (max-width: 370px) {
    .resume-page-skills {
        flex-direction: column;
    }
}
