.template-editing {
    max-width: 930px;
}

.field-sets-wrap .field-sets-item {
    padding: 23px 0;
}

.field-sets-item-link {
    min-width: 0;
    font-size: 18px;
    color: #26b9a1;
    padding-right: 30px;
    word-wrap: break-word;
}

.template-editing-title {
    font-size: 24px;
    color: #354169;
    font-weight: 400;
    letter-spacing: 0.6px;
    margin-bottom: 45px;
}

.template-editing-title span {
    font-weight: 700;
}

/* render-actions-wrap */

.render-actions-wrap {
    margin-bottom: 65px;
}

.template-editing-form-heading {
    padding-bottom: 17px;
}

.render-actions {
    margin-bottom: 22px;
}

.render-actions-setup-container {
    position: relative;
    padding: 22px 0 45px;
}

.render-actions-setup-container:first-child {
    padding-top: 45px;
}

.render-actions-setup-container:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    height: 1px;
    background-color: #ced3d9;
}

.render-actions-setup-container:last-child:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;
    height: 1px;
    background-color: #ced3d9;
}

.render-actions-container {
    position: relative;
    padding: 30px 0;
    border-bottom: 1px solid #f1f3f7;
}

.render-actions-container:first-child {
    border-top: 1px solid #f1f3f7;
}

.render-actions-container:last-child {
    border-bottom: none;
}

.render-actions-container .form-group {
    padding-right: 35px;
}

.render-actions-container .form-group:last-of-type {
    margin-bottom: 0;
}

.render-actions-title {
    display: block;
    color: #354169;
    font-size: 16px;
    margin-bottom: 5px;
    padding-left: 23px;
}

.render-actions-setup-header .render-creatable-select-container {
    padding-top: 30px;
}

.render-actions-setup-container:first-child .render-actions-setup-header .render-creatable-select-container {
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 30px;
}

.render-actions-delete {
    display: flex;
    position: absolute;
    margin-top: 30px;
    right: 5px;
}

.render-actions-change {
    display: flex;
    order: 0;
    position: absolute;
    top: 20px;
    left: calc(50% - 85px);
    transform: rotate(90deg);
}

@media screen and (max-width: 1200px) {
    .render-actions-change {
        left: 337px;
    }
}

@media screen and (max-width: 991px) {
    .render-actions-change {
        top: -15px;
        left: 45%;
        background: white;
        padding: 7px;
    }
    .render-actions-delete {
        top: -10px;
    }
    .initial-actions {
        padding-right: 30px;
    }
}

.render-creatable-select-container {
    width: 100%;
}

.edit-fields-sets {
    max-width: 700px;
}

.edit-fields-disable-edit-text {
    font-size: 18px;
    color: #354169;
    letter-spacing: 0.45px;
}

.render-field-head {
    max-width: 450px;
}

/* create-field-form */

.create-new-field {
    margin-bottom: 35px;
}

.create-field-form .form-group {
    margin-bottom: 0;
}

.create-field-form-type-select {
    padding: 20px 0 35px;
}

.render-field-item-list {
    margin-bottom: 65px;
}

.render-field-item {
    display: flex;
    flex-wrap: wrap;
    padding: 35px 0 30px;
    border-top: 1px solid #f1f3f7;
}

.render-field-item .form-group {
    margin-bottom: 0;
}

.render-field-item-select {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.render-field-item-num {
    color: #b7beca;
    font-size: 16px;
    margin-right: 7px;
}

.render-field-item-bottom {
    display: flex;
    width: 100%;
}

.render-field-item-bottom label {
    margin-bottom: 0;
}

.render-field-item .item-icon {
    display: block;
    margin-left: auto;
    margin-right: 13px;
}

.render-field-item .form-group label {
    display: none;
}

/* render-checkbox-container */

.render-checkbox-container {
    margin-left: 25px;
}

@media screen and (min-width: 576px) {
    .render-actions-setup-container:before,
    .render-actions-setup-container:last-child:after {
        display: none;
    }

    .render-actions-setup-container {
        border-radius: 10px;
        border: 1px solid #ced3d9;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media screen and (min-width: 768px) {
    .field-sets-item-link {
        font-size: 20px;
    }
    .field-sets-wrap .field-sets-item {
        padding: 28px 0;
    }
    .render-field-item {
        flex-wrap: unset;
        align-items: center;
        border: none;
        padding: 25px 0 0;
    }
    .render-field-item-select {
        margin-bottom: 0;
    }
    .create-element-container {
        width: 100%;
    }
    .create-field-form-new-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .create-field-form {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
    }
    .create-field-form > div {
        width: 250px;
    }
    .create-field-form .create-field-form-type-select {
        margin-left: 13px;
    }
    .create-field-form-type-select {
        padding: 0;
    }
    .render-field-item-select {
        max-width: 445px;
        flex-shrink: 0;
    }
}

@media screen and (min-width: 992px) {
    .template-editing-title {
        max-width: 460px;
    }
    .template-editing .template-editing-title{
        padding-left: 62px;
    }
    .template-editing-form-heading {
        max-width: 460px;
        padding-left: 40px;
    }
    .render-actions-container {
        border-bottom: none;
    }
    .render-actions-setup-header .render-creatable-select-container {
        max-width: 335px;
        margin-bottom: 0;
        padding-top: 20px;
    }
    .render-actions-container .form-group {
        max-width: 335px;
        padding-right: 0;
        width: 100%;
    }
    .render-actions-setup-container:first-child .render-actions-setup-header .render-creatable-select-container {
        padding-bottom: 0;
    }
    .render-actions-container .form-group:first-child {
        margin-right: 20px;
    }
    .render-actions-delete {
        position: static;
        margin-left: 40px;
    }
    .render-actions-container {
        display: flex;
        align-items: center;
    }
    .render-actions-setup-container:first-child {
        padding-top: 45px;
    }
    .render-actions-setup-container {
        padding: 45px 40px;
    }
    .render-actions-container {
        padding: 35px 0 0;
    }
    .render-actions-container .form-group:first-child {
        margin-bottom: 0;
    }
    .render-actions-container:first-child {
        border-top: none;
    }
    .render-actions-container:last-child {
        padding-bottom: 40px;
    }
    .render-field-item-num {
        margin-right: 30px;
    }
    .render-checkbox-container {
        margin-left: 35px;
    }
    .create-field-form > div {
        width: 280px;
    }
}

.entities-edit-chevron-icon:not(:hover) {
    margin: 0 10px;
    font-size: 10px;
    color: #02a88d;
}

.entities-edit-chevron-container:hover .entities-edit-chevron-icon {
    color: #047b63;
    margin: 0 10px;
    font-size: 10px;
    transition: color 0.3s ease;
}

.entities-edit-chevron-container {
    cursor: pointer;
    margin-left: 15px;
    margin-top: 10px;
}

.entities-container-edit {
    list-style: none;
    margin: 0;
    padding: 0;
}

.entities-container-edit .create-element-container,
.entities-container-edit .create-field-form-new-container {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}
.entities-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.width-half{
    width: 50%;
}
.margin-low{
    margin: 15px;
}

.margin-low-vertical{
    margin-top: 15px;
    margin-bottom: 15px;
}