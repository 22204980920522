.all-vacancies-page{
    background-color: #eff4f4;
}

.text-color-dark-blue {
    color: #354169;
}

.all-vacancies-content {
   min-height: 97.5vh
}

.title-padding {
    padding-top: 50px;
}

.allvacancy-item-info-wrapper {
    width: 100%;
    padding-left: 15px;
}

.allvacancy-picture-wrapper {
    min-width: 80px;
    max-width: 80px;
    height: 100px;
    overflow: hidden;
}

.allvacancy-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5%;
}

.time-filters-wrapper {
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
}

.vacancy-counter {
    margin: 0;
    font-size: 16px;
}

.allvacancy-item-city {
    margin-bottom: 8px;
}

.main-page-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.allvacancy-item-wrapper {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
}

.allvacancy-item-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.allvacancy-item-title {
    color: #02a88d;
    margin: 0;
}

.allvacancy-item-desc * {
    font-size: 16px !important;
    color: #74757b!important;
    line-height: 22px;
}

.allvacancy-item-title:hover {
    text-decoration: underline;
}

.allvacancy-item-desc-wrapper {
    justify-content: space-between;
}

.allvacancy-item-exp {
    font-size: 15px;
    color: #a5aebd;
}

.allvacancy-item-salary {
    font-size: 16px;
    text-align: right;
}

.allvacancy-item-desc {
    overflow-wrap: break-word;
    max-height: 65px;
    min-height: 25px;
    overflow: hidden;
    position: relative;
    line-height: 16px;
}

.allvacancy-item-desc-ellipsis {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    text-align: right;
    background: linear-gradient(to right, rgba(255, 255, 255, .5), #fff 70%);
}

.allvacancy-item-date {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    word-wrap: break-word;
    min-width: 80px
}

@media screen and (max-width: 992px) {
    .main-page-title-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .all-vacancies-title {
        margin-bottom: 10px;
        font-size: 30px;
    }
}

@media screen and (max-width: 768px) {

    .time-filter {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 580px) {
    .time-filters-wrapper {
        flex-direction: column-reverse;
        justify-content: flex-start;
    }

    .time-filter {
        margin-bottom: 10px;
    }

    .allvacancy-item-salary {
        font-size: 14px;
    }

    .allvacancy-picture-wrapper {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .allvacancy-item-desc-wrapper {
        flex-direction: column;
    }

    .allvacancy-item-date {
        margin: 10px 0 0 0;
        justify-content: left;
        color: #a5aebd;
    }

}
