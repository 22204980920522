/* login and registration */

.auth-content {
    max-width: 450px;
    background-color: #fff;
    margin: 0 auto;
    padding: 55px 35px 35px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);
}

.auth-content .auth-btns-wrap {
    padding-top: 35px;
}

.auth-content .auth-btns-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.auth-content .auth-license-text {
    color: #96a0b1;
    font-size: 14px;
    letter-spacing: 0.35px;
    margin-top: 20px;
    line-height: 1.3;
}

h2.auth-title {
    max-width: 450px;
    margin: 0 auto 30px;
    color: #354169;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    word-wrap: break-word;
}

.auth-text {
    color: #354169;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}

.auth-btns-wrap .button {
    width: 100%;
    margin-bottom: 15px;
}

/* email sent */

.sign-up-success-content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 130px 15px 0;
    text-align: center;
}

.sign-up-success-content .icon-wrap {
    margin-bottom: 50px;
}

.sign-up-success-content .icon-wrap .item-icon {
    font-size: 50px;
    color: #00b491;
}

.sign-up-success-page-title {
    color: #354169;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.sign-up-success-page-text {
    color: #354169;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 60px;
}

@media screen and (min-width: 480px) {
    .auth-content {
        border-radius: 10px;
    }
    .auth-content .auth-btns-wrap {
        flex-direction: row;
        justify-content: center;
    }
    .auth-content .auth-license-text {
        margin-top: 35px;
    }
    h2.auth-title {
        text-align: left;
    }
    .sign-up-success-content {
        padding: 130px 0 0;
    }
    .auth-btns-wrap .button {
        width: 100%;
        margin-bottom: 40px;
    }     
}

.signUp-type-change-wrapper .signUp-type-change {
    color: #00b491;
    background-color: #fff;
    border: 2px solid #00b491;
    line-height: 40px;
    max-width: 180px;
    font-size: 13px;
    padding: 0;
    text-align: center;
    width: 100%;
}

.signUp-type-change-wrapper {
    display: flex;
    justify-content: space-between;
}

.signUp-type-change:hover {
    color: #fff;
}

@media screen and (max-width: 480px) {
    .signUp-type-change-wrapper {
        flex-direction: column;
    }

    .signUp-type-change-wrapper .signUp-type-change {
        max-width: none;
    }

    .signUp-type-change-wrapper .signUp-type-change:first-child {
        margin-bottom: 10px;
    }
}

.account-activation-success-wrapper {
    margin-top: 200px;
}